<template>
  <div class="container mescroll-touch">
    <div class="top">
      <img class="menuIcon" src="@/assets/images/menu_comm_icon@2x.png" @click="sidebarShow = true" />
      <div class="searchBox" @click="$router.push('/search')">
        <img class="searchIcon" src="@/assets/images/search_comm_icon@2x.png" />
        <span class="searchInput">请输入关键字搜索</span>
        <!-- <input class="searchInput" type="text" placeholder="请输入关键字搜索" disabled /> -->
      </div>
    </div>
    <div class="content">
      <div class="banner" v-show="videoUrl">
        <video
          :id="videoId"
          style="width: 100%; height: 100%;"
          preload="auto"
          playsinline=""
          webkit-playsinline=""
          x5-playsinline=""
        ></video>
      </div>
      <p class="title" v-if="code == 'csekt'">病例专区</p>
      <div class="box">
        <div class="menu">
          <div class="menu_item" v-for="(item, index) in menuList" :key="index" @click="toPage(item.DepartmentCode, item.SubTCode, item.SubName, item.UrlLink)">
            <img :src="item.File_Url" />
            <span>{{item.SubName}}</span>
          </div>
        </div>
        <p class="title" v-if="code == 'csekt'">最新活动</p>
        <div v-if="ruleDescription">
          <img class="ruleDescription" :src="ruleDescription" />
        </div>
      </div>
    </div>
    <Sidebar :sidebarShow="sidebarShow" @popupClose="popupClose" />
  </div>
</template>

<script>
import mixin from '../../common/mixin/mixins'
import Sidebar from '../../components/sidebar.vue'
export default {
  name: 'zxbCompetition',
  data() {
    return {
      sidebarShow: false,
      videoUrl: '',
      menuList: [],
      ruleDescription: '',
      videoId: '',
      player: null,
    }
  },
  mixins: [mixin],
  components: {
    Sidebar
  },
  computed: {
    code() {
      return this.$route.query.code;
    },
  },
  watch: {
    '$route'(to, from) {
      this.getData();
    }
  },
  created() {
    this.videoId = this.randomString(6);
  },
  mounted() {
    this.getData();
  },
  methods: {
    popupClose(val) {
      this.sidebarShow = val;
    },
    // 初始化播放器
    initVideo(videoObj) {
      // if (!this.player) {
      //   this.player = this.TCPlayer(this.videoId, {
      //     // autoplay: true,
      //     loop: true,
      //     poster:img,
      //     licenseUrl: 'https://license.vod2.myqcloud.com/license/v2/1307224350_1/v_cube.license'
      //   }).player;
      //   this.player.src(url);
      // }
      let Options = {};
      if(videoObj.fileid) {
        Options = {
          fileID: videoObj.fileid,
          appID: videoObj.appid,
          psign: videoObj.psign,
          licenseUrl: 'https://license.vod2.myqcloud.com/license/v2/1307224350_1/v_cube.license'
        };
        this.player = this.TCPlayer(this.videoId, Options, {
          app_Play_Id: videoObj.app_Play_Id,
          app_Id: 'wcwechat',
          user_Id: videoObj.user_Id,
          video_Id: videoObj.VideoId
        },{
          Id: this.userInfo.Id,
          RealName: this.userInfo.RealName,
          HospitalTitle: this.userInfo.HospitalTitle,
          PositionalName: this.userInfo.PositionalName,
          SubjectName: this.userInfo.SubjectName,
          Phone: this.userInfo.Phone
        }, videoObj.Name, videoObj.videoSize, videoObj.fileid).player;
      } else {
        Options = {
          playbackRates: [0.5, 1, 1.5, 2],
          licenseUrl: 'https://license.vod2.myqcloud.com/license/v2/1307224350_1/v_cube.license'
        };
        this.player = this.TCPlayer("player-container-id", Options).player;
        this.player.src(videoObj.Link);
      }
    },
    getData() {
      this.$axios.get('/Api/api/Web/Article/GetVUrl?sCode=' + this.code).then(res => {
        if(res.Data.VU.length > 0) {
          this.videoUrl = res.Data.VU[0].Link;
          this.initVideo(res.Data.VU[0]);
        }
        this.menuList = res.Data.IF;
        if(res.Data.Img) {
          this.ruleDescription = res.Data.Img.File_Url;
        }
      })
    },
    toPage(DCode, TCode, Name, Link) {
      if (Link) {
        this.BuriedPoint(1, Name, TCode, Link);
        this.$router.push({
        path: Link,
        query: {
          code: this.code,
          dcode: DCode,
          tcode: TCode,
          title: Name
        }
      });
      } else {
        this.$dialog.alert({
          title: '提示',
          message: '敬请期待',
        }).then(() => {});
      }
    },
    randomString(len) {
      len = len || 32
      var $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
      var maxPos = $chars.length
      var pwd = ''
      for (let i = 0; i < len; i++) {
        pwd += $chars.charAt(Math.floor(Math.random() * maxPos))
      }
      return pwd
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  min-height: 100%;
  padding: 16px 0;
  box-sizing: border-box;
  background-image: url('../../assets/images/bg_comm_png@2x.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #F9F9F9;
  .top {
    margin-bottom: 6px;
    display: flex;
    padding: 0 12px;
    .menuIcon {
      width: 30px;
      height: 30px;
    }
    .searchBox {
      display: flex;
      align-items: center;
      flex: 1;
      height: 30px;
      line-height: 30px;
      border-radius: 30px;
      background-color: rgba(255, 255, 255, 0.9);
      padding: 0 15px;
      margin-left: 10px;
      .searchIcon {
        width: 14px;
        height: 14px;
      }
      .searchInput {
        flex: 1;
        margin-left: 4px;
        border: none;
        background-color: transparent;
        color: #999999;
      }
    }
  }
  .content {
    padding: 12px 12px;
    background-color: #F9F9F9;
    border-radius: 12px 12px 0 0;
    margin-top: 16px;
    .banner {
      height: 198px;
      border-radius: 10px;
      margin-bottom: 15px;
      overflow: hidden;
    }
    .title {
        position: relative;
        font-size: 15px;
        padding: 10px 10px 10px 15px;
        color: #006991;
      }
      .title::before {
        content: "";
        position: absolute;
        top: 11px;
        left: 7px;
        width: 3px;
        height: 18px;
        border-radius: 3px;
        background-color: #006991;
      }
    .box {
      background: #FFFFFF;
      border-radius: 10px 8px;
      .menu {
        display: flex;
        flex-wrap: wrap;
        // padding: 10px 0;
        .menu_item {
          text-align: center;
          padding: 10px;
          box-sizing: border-box;
          width: calc(100% / 3);
          img {
            display: block;
            width: 55px;
            height: 55px;
            margin: 0 auto 10px;
          }
          span {
            font-size: 12px;
            color: #333333;
          }
        }
      }
      .ruleDescription {
        width: 100%;
      }
    }
  }
}
</style>